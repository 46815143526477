import { render } from 'inferno'
import 'whatwg-fetch'

import TutorialLightbox from '@components/tutorial-lightbox'

import delegate from 'delegate'

import {
	forEach, getElementByHashLink, sanitizeElementData
} from '@misc/utilities'

import { infernoRemoveRootNode } from '@misc/utilities-inferno'

const bootstrapLightbox = () => {
  console.debug('Bootstrapping Lightbox...')
  connectNodes('.atto_imagegallery', openModal)
}

const connectNodes = (selector, callback) => {
	const nodeList = document.querySelectorAll(selector)
	let element

  const delegation = delegate(selector, 'click', e => {

    callback(e, e.delegateTarget)
  }, false)
}

const openModal = (e, element) => {
  if(element.closest('.editor_atto_content')) { return }

  e.preventDefault()
  e.stopPropagation()

  const imageElem = element.querySelector('img')

  const data = {
    title: element.querySelector('figcaption').textContent,
    description: element.dataset.description,
    url: imageElem.src
  }

  renderModal(data)
}

const renderModal = ({ title, url, description }) => {

	let modalContainer = document.getElementById('modal-container')

	if(!modalContainer) {
		modalContainer = document.createElement('div')
		modalContainer.id = 'modal-container'
		document.body.insertBefore(modalContainer, document.body.firstChild)
	}

	const props = {
    title, url, description,
		destroy: infernoRemoveRootNode(modalContainer)
	}

  render( <TutorialLightbox {...props} />, modalContainer)
}

const fetchImageData = () => {
  fetch(`/theme/rainbow/ajax/images.php?courseid=${THEMEDATA.courseid}&page_instanceid=${THEMEDATA.mod_instanceid}&sesskey=${M.cfg.sesskey}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin'
  })
    .then(response => response.json())
    .then(json => {
      console.log(json)
    })
}

export default bootstrapLightbox
