import Inferno from 'inferno'
import Component from 'inferno-component'
import classNames from 'classnames'
import marked from 'marked'

import { animationEvent } from '@misc/utilities'

const KEY_ARROW_LEFT = 37
const KEY_ARROW_RIGHT = 39
const KEY_ESC = 27

class TutorialLightbox extends Component<any, any> {

  public wrapperElement

  constructor(props) {
    super(props)

    this.state = {
      isFading: false,
      padding: 20,
      currentIndex: null,
      imageData: []
    }

    this.scrollbarSet()

    if(props.beforeOpen) {
      props.beforeOpen()
    }

    // events
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleClickBackground = this.handleClickBackground.bind(this)
    this.handleKeyup = this.handleKeyup.bind(this)

    this.loadNextImage = this.loadNextImage.bind(this)
    this.loadPrevImage = this.loadPrevImage.bind(this)
  }

  componentDidMount() {
    if(this.props.onOpen)
      this.props.onOpen(this.wrapperElement)

    this.fetchImageData()

    this.registerKeys()
  }

  componentWillUnmount() {
    this.unregisterKeys()
  }

  handleClickClose(e) {
    e.preventDefault()
    e.stopPropagation()

    this.closeModal()
  }

  get currentItem() {
    return this.state.imageData[this.state.currentIndex]
  }

  handleClickBackground(e) {
    if(!e.target.closest('.modal'))
      this.closeModal()
  }

  closeModal() {
    this.setState({ isFading: true })

    if(this.props.beforeClose)
      this.props.beforeClose(this.wrapperElement);

    return animationEvent ?
      this.wrapperElement.addEventListener(animationEvent, e => this._closeModal()) :
      this._closeModal()
  }

  _closeModal() {

    if(this.props.onClose)
      this.props.onClose()

    this.scrollbarUnset()
    this.props.destroy()
  }

  scrollbarSet() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    document.body.classList.add('modal-open')
    document.body.style.paddingRight = scrollbarWidth + 'px'
  }

  scrollbarUnset() {
    document.body.classList.remove('modal-open')
    document.body.style.paddingRight = '0'
  }

  get nextImageIndex() {
    const newIndex = this.state.currentIndex + 1
    return newIndex < this.state.imageData.length ? newIndex : -1
  }

  get prevImageIndex() {
    const newIndex = this.state.currentIndex - 1
    return newIndex >= 0 ? newIndex : -1
  }

  loadNextImage() {
    if(this.nextImageIndex !== -1) {
      this.setState({ currentIndex: this.nextImageIndex })
    }
  }

  loadPrevImage() {
    if(this.prevImageIndex !== -1) {
      this.setState({ currentIndex: this.prevImageIndex })
    }
  }

  handleKeyup(e) {
    e.preventDefault()
    e.stopPropagation()

    switch(e.keyCode) {
      case KEY_ARROW_LEFT:  { return this.loadPrevImage() }
      case KEY_ARROW_RIGHT: { return this.loadNextImage() }
      case KEY_ESC:         { return this.closeModal() }
    }
  }

  registerKeys() {
    document.addEventListener('keyup', this.handleKeyup)
  }

  unregisterKeys() {
    document.removeEventListener('keyup', this.handleKeyup)
  }

  fetchImageData() {
    const { courseid } = THEMEDATA
    fetch(`/theme/rainbow/ajax/images.php?courseid=${courseid}&sesskey=${M.cfg.sesskey}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(json => {

        const imageData = json.ImageData
        const currentIndex = imageData.findIndex(item => item.url == this.props.url)

        this.setState({
          currentIndex, imageData,
        })
      })
  }

  render() {

    const { width, height, nopadding, type } = this.props

    let modalStyles = {
      maxWidth: nopadding && width ? width : width + this.state.padding * 2,
    }

    let modalContentStyles = {
      padding: nopadding ? null : this.state.padding,
      height: height || null,
    }

    const classes = classNames(
      'animate',
      {
        'fading': this.state.isFading,
        'nopadding': nopadding,
        [`modal-${type}`]: type
      }
    )

    const wrapperProps = {
      ref: node => this.wrapperElement = node,
      className: `modal-wrapper ${classes}`,
      onClick: this.handleClickBackground
    }

    return (
      <div {...wrapperProps}>
        <div class="modal" style={modalStyles}>
          <div class="close-button" onClick={this.handleClickClose}></div>
          <div class="modal-inner">
            { this.renderTitle() }
            { this.renderContent(modalContentStyles) }
            { this.renderNavigation() }
          </div>
        </div>
      </div>
    )
  }

  renderTitle() {

    if(!this.state.imageData.length || !this.currentItem) { return null }

    const title = this.currentItem.shortdescription

    return (
      <div class="modal-title">{ title }</div>
    )
  }

  renderContent(contentStyles) {

    if(!this.state.imageData.length || !this.currentItem) {
      return (
        <div class="modal-content" style={contentStyles}>
          <img src={this.props.url} />
        </div>
      )
    }

    if(this.currentItem.description) {
      return (
        <div class="modal-content modal-content--with-sidebar" style={contentStyles}>
          <div class="modal-content_main">
            <img src={this.currentItem.url} />
          </div>
          <div class="modal-content_sidebar" dangerouslySetInnerHTML={{ __html: marked(this.currentItem.description) }}></div>
        </div>
      )
    }
    else {
      return (
        <div class="modal-content" style={contentStyles}>
          <div class="modal-content_main">
            <img src={this.currentItem.url} />
          </div>
        </div>
      )
    }
  }

  renderNavigation() {
    const nextImageIndex = this.nextImageIndex
    const prevImageIndex = this.prevImageIndex

    if(!prevImageIndex && !nextImageIndex) {
      return null
    }

    return (
      <div class="modal_navigation">
        {
          prevImageIndex == -1 ? null :
            <div class="modal_navigation_side modal_navigation_side--prev" onClick={this.loadPrevImage}>
              <div class="modal_navigation_arrow"></div>
            </div>
        }
        {
          nextImageIndex == -1 ? null :
            <div class="modal_navigation_side modal_navigation_side--next" onClick={this.loadNextImage}>
              <div class="modal_navigation_arrow"></div>
            </div>
        }
      </div>
    )
  }
}

export default TutorialLightbox
