import { forEach } from '@misc/utilities'

export default function bootstrapCourseNavigationBar() {
  console.debug('Bootstrapping CourseNavigationBar...')

  const navIndexElems = document.getElementsByClassName('course-navigation-bar')

  forEach(navIndexElems, navIndexElem => {
    new CourseNavigationBar(navIndexElem)
  })
}

class CourseNavigationBar {

  public isOpen = false
  public elem
  public indexElem
  public indexToggleElem
  public indexContentElem

  constructor(elem) {
    this.elem = elem

    this._indexContentBlurHandler = this._indexContentBlurHandler.bind(this)
    this._indexToggleClickHandler = this._indexToggleClickHandler.bind(this)

    this.init()
  }

  public init() {
    this.indexElem = this.elem.querySelector('.navigation-index')
    this.indexToggleElem = this.elem.querySelector('.navigation-index_toggle')
    this.indexContentElem = this.elem.querySelector('.navigation-index_content')

    if(this.indexToggleElem) {
      this.indexToggleElem.addEventListener('click', this._indexToggleClickHandler)
    }
  }

  private _indexToggleClickHandler(e) {
    e.stopPropagation()
    this.toggle()
  }

  private _indexContentBlurHandler(e) {
    e.stopPropagation()
    if(e.target.closest('.navigation-index_content') !== this.indexContentElem) {
      console.log('blur')
      this.close()
    }
  }

  public toggle() {
    console.log('toggle')
    return this.isOpen ?
      this.close() : this.open()
  }

  public close() {
    console.log('close')
    this.indexElem.classList.remove('open')
    this.isOpen = false
    document.removeEventListener('click', this._indexContentBlurHandler) 
  }

  public open() {
    console.log('open')
    this.indexElem.classList.add('open')
    this.isOpen = true
    document.addEventListener('click', this._indexContentBlurHandler) 
  }

}
